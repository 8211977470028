<template>
    <section class="almacen-main">
        <navbar-admin />
        <tabs :tabs="tabsAlmacen" />
        <router-view />
    </section>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data(){
        return{
            titulo:'',
            tabsAlmacen: [
                {
                    titulo:'Categorias',
                    ruta:"almacen.categorias",
                },
                {
                    titulo:'Materiales',
                    ruta:"almacen.materiales",
                },
                {
                    titulo:'Stock',
                    ruta:"almacen.stock",
                },
                {
                    titulo:'Etapas',
                    ruta:"almacen.etapas",
                },
                {
                    titulo:'Equipos modelos',
                    ruta:"almacen.equipos",
                },
                {
                    titulo:'Mantenimiento',
                    ruta:"almacen.mantenimiento",
                },
                {
                    titulo:'Pedidos internos',
                    ruta:"almacen.pedidos.internos",
                },
            ],
        }
    },
    created(){
        this.getBreadcumbs(['almacen.main']);
        this.replaceBreadcumb({position: 1, breadcumb: this.$route.name })
    },
    methods:{
        ...mapActions({
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            replaceBreadcumb: 'navigation/breadcumbs/replaceBreadcumb',
            deleteBreadcumbs: 'navigation/breadcumbs/deleteBreadcumbs'
        })
    }
}
</script>
<style lang="scss" scoped>
</style>